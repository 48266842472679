import axios from "axios";
import Vue from 'vue';
// import $router from '../router/route'
// import {
//     Loading
// } from 'element-ui';
import _ from 'lodash';
import env from '../../env';
import {Toast} from "vant"
import {clearCacheData} from "./base";

let pending = [];
let cancelToken = axios.CancelToken;
let removePending = (config) => {
    for (let i in pending) {
        if (pending[i].url === config.url) {
            pending[i].f();

        }
    }
}

const zaxios = axios.create({
    headers: {
        "system-source":'work_wechat',
        "system-type":"chat"
    },
    baseURL: env.baseUrl,
})

zaxios.interceptors.request.use(
   
    function (config) {
        if(config.url=='/api/v1/wechat/qy/work_wechat_chat/chat_list'){

        }else{
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
            })
        }
        console.log(config,'..config')
        console.log(localStorage.getItem('token'), 'request token');
        if (localStorage.getItem('token')) {
            config.headers.token = localStorage.getItem('token')
        }
        return Promise.resolve(config);
    },
    function (error) {
        // closeLoading()
        return Promise.reject(error)
    }
)

zaxios.interceptors.response.use(
    function (response) {
        // closeLoading()
        let res = null;
        if (response.status === 200) {
            if (response.data && response.data.code === 200) {
                res = response.data
            } else if (response.data && response.data.size && response.data.type) {
                res = response.data
                let filname = ''
                if (response.headers["content-disposition"]) {
                    filname = response.headers["content-disposition"].split("filename*=utf-8''")[1]
                }
                const fileName = decodeURIComponent(filname)
                const link = document.createElement('a')
                let blob = new Blob([res], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
                })
                link.style.display = 'none'
                link.href = URL.createObjectURL(blob)
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                Vue.prototype.$message.success('成功')
            }
        }
        return response.data
    },
    function (error) {
        let msgs = error.response.data.msg ? error.response.data.msg : error.response.data.message ? error.response.data.message : '请求错误';
        getMessage(msgs, error.response.status)
        return Promise.resolve(error, 'erro')
    }
)

let timer = null
function getMessage(msgs, err) {
    let msg = msgs ? msgs : '请求错误'
    if (timer) {
        // closeLoading()
        clearTimeout(timer)
        // closeLoading()
        timer = setTimeout(() => {
            if (msg) {
                switch (err) {
                    case 401:
                        clearCacheData();
                        Toast(msg)
                        break;
                    case 40006:
                        Toast(msg)
                        break;
                    default:
                        Toast(msg)
                        break;
                }
                return
            }
        }, 300)
    } else {
        timer = setTimeout(() => {
            switch (err) {
                case 401:
                    clearCacheData();
                    Toast(msg)
                    break;
                case 40006:
                    Toast(msg)
                    break;
                default:
                    Toast(msg)
                    break;
            }
        }, 300)
    }
}
export default zaxios